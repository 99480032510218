<template>
  <div
    :class="[
            usedInModal
            ? 'w-full lg:w-256'
            : 'w-full'
        ]"
  >
    <zone-select-input
      v-model="selectedZoneId"
    />

    <vue-cal
      :disable-views="['years', 'year', 'month', 'day']"
      :events="formattedTimeSlotsForCalendar"
      :time-from="hourBegin * 60"
      :time-to="hourEnd * 60"
      active-view="week"
      hide-view-selector
      locale="fr"
      @view-change="onViewChange"
      @event-focus="onEventFocus($event)"
    >
      <template #no-event>
        <span></span>
      </template>

      <template v-slot:event="{ event }">
        <small class="vuecal__event-time">
          <span>{{ event.start.formatTime() }}</span> - <span>{{ event.end.formatTime() }}</span>
        </small>

        <div>
          <fa-icon class="mr-2 | hidden md:inline-block" fixed-width icon="truck"/>
          {{ event.title }}
        </div>
      </template>

    </vue-cal>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/i18n/fr.amd.js';
import 'vue-cal/dist/vuecal.css'
import ZoneSelectInput from "@/components/elements/forms/elements/ZoneSelectInput";

export default {
  name: "TimeSlotSelection",
  components: {ZoneSelectInput, VueCal},
  props: {
    value: {
      validator: val => typeof val === "number" || val === null,
      required: false
    },
    zoneId: {
      type: Number,
      default: 1,
      required: true,
    },
    /**
     * Can be passed if not using v-model, but listening to the change event instead.
     * When passed, the timeslot in the calendar with the matching id will be styled as selected instead.
     */
    selectedId: {
      type: Number,
    },
    usedInModal: {
      type: Boolean,
      default: false
    },
    initialFetch: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    zoneId(val) {
      this.selectedZoneId = val;
    },
    selectedZoneId() {
      this.timeSlots = [];
      this.fetchTimeslotsForZone();
      this.$emit('input', null);
    }
  },
  data() {
    let startDate = this.$date().startOf('week');
    let endDate = this.$date().endOf('week');

    return {
      startDate: startDate,
      endDate: endDate,

      // The time the table should show of each day.
      hourBegin: 8,
      hourEnd: 23,

      selectedZoneId: null,
    }
  },
  mounted() {
    // Set v-model to prop value
    this.selectedZoneId = this.zoneId;

    if (this.initialFetch) {
      this.fetchTimeslotsForZone();
    }
  },
  computed: {
    formattedTimeSlotsForCalendar() {
      return this.getAvailableTimeSlots.map(slot => {
        let title = `${slot.deliveryCount}/${slot.deliveryLimit} liv.`;
        let classes = slot.isAvailable ? 'slot-available' : 'slot-occupied';
        if (this.isSelected(slot.id)) {
          classes += ' selected';
        }

        return {
          timeSlotId: slot.id,
          start: slot.startDate,
          end: slot.endDate,
          available: slot.isAvailable,
          title: title,
          class: classes,
          zone: slot.zone
        };
      });
    },
    getAvailableTimeSlots() {
      return this.$store.getters['cart/getTimeSlots']?.timeSlots || [];
    }
  },
  methods: {
    fetchTimeslotsForZone() {
      this.$store.dispatch('cart/fetchTimeSlots', {
        zone: this.selectedZoneId,
        startDate: this.startDate.format("YYYY-MM-DD HH:mm"),
        endDate: this.endDate.format("YYYY-MM-DD HH:mm"),
      });
    },
    onViewChange(e) {
      this.startDate = e.startDate;
      this.endDate = e.endDate;

      return this.fetchTimeslotsForZone();
    },
    isSelected(timeSlotId) {
      if (this.value === undefined) {
        return this.selectedId === timeSlotId;
      }

      return this.value === timeSlotId;
    },
    selectEvent(event) {
      //Timeslot Id
      if (this.value !== undefined) {
        this.$emit('input', event.id);
      }
      this.$emit('change', event);
    },
    onEventFocus(event) {
      this.selectEvent({
        id: event.timeSlotId,
        start: event.start,
        end: event.end,
        zone: event.zone
      });
    }
  }

}
</script>

<style>
.vuecal__title-bar {
  @apply bg-luxcaddy-500 text-white;
}

.vuecal__event.slot-available {
  @apply bg-luxcaddy-300 border border-luxcaddy-400 text-white transform
  hover:scale-105 cursor-pointer hover:shadow-xl hover:bg-luxcaddy-400 select-none;
}

.vuecal__event.slot-available.selected {
  @apply bg-luxcaddy-500 border-black scale-110;
}

.vuecal__event.slot-occupied {
  @apply bg-red-300 border border-red-400 text-white;
}

.vuecal__event.slot-occupied.selected {
  @apply bg-red-500 border border-black text-white  scale-110;
}
</style>