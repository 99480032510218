import auth from "../middlewares/authMiddleware";
import PrintFulfilmentLabelPage from "@/components/pages/PrintFulfilmentLabel/PrintFulfilmentLabelPage";
import PrintBarcodePage from "@/components/pages/PrintBarcode/PrintBarcodePage.vue";

export default [
  {
    path: '/print-fulfilment-label',
    name: 'fulfilmentlabel.print.index',
    component: PrintFulfilmentLabelPage,
    meta: {
      middleware: [auth],
      title: 'Imprimer étiquette fulfilment',
      permissions: ['FULFILMENT_PRINT_LABEL']
    }
  },
  {
    path: '/print-barcode',
    name: 'barcode.print.index',
    component: PrintBarcodePage,
    meta: {
      middleware: [auth],
      title: 'Imprimer code barre',
      permissions: ['FULFILMENT_PRINT_LABEL']
    }
  },
];