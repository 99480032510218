import RouteRepository from "@/repositories/RouteRepository";
import DeliveryRepository from "@/repositories/DeliveryRepository";
import MemberRepository from "@/repositories/MemberRepository";
import {vue} from "@/main";
import {getColorByRouteIndex} from "@/helpers/routeManagerHelper";
import axios from "axios";
import dayjs from "dayjs";

function getColorForRouteFunc(state, routeId) {
  let route = state.routes.find(r => r.id === routeId);
  return route.hexColor;
}

function findRouteByDeliveryId(state, deliveryId) {
  return state.routes.find(route => {
    let delivery = route.steps
      .filter(step => step.delivery !== undefined)
      .find(step => step.delivery.id === deliveryId);

    return delivery !== undefined;
  });
}

function findRouteById(state, routeId) {
  return state.routes.find(route => route.id === routeId);
}

function getDeliveryIdsOfRoute(state, routeId) {
  let route = state.routes.find(r => r.id === routeId)

  if (!route) {
    return [];
  }

  return route
    .steps
    .filter(step => step.delivery !== undefined)
    .flatMap(s => s.delivery.id);
}

function removeHiddenDeliveries(state, deliveryIds) {
  let deliveryIdsToRemoveSet = new Set(deliveryIds);
  state.hiddenDeliveryIds = state.hiddenDeliveryIds.filter(id => !deliveryIdsToRemoveSet.has(id));
}

function getDeliveriesByTimeSlotStartAndEndTime(state, startTime, endTime) {
  return state.deliveries.filter(d => {
    return dayjs(d.timeSlot.startDate).format('HH:mm') === startTime &&
      dayjs(d.timeSlot.endDate).format('HH:mm') === endTime;
  })
}

const getDefaultState = () => {
  return {
    sideBarCols: 1,

    routes: [],
    deliveries: [],
    employees: [],

    initialRoutesLoaded: false,
    initialDeliveriesLoaded: false,

    selectedDate: null,

    selectedDeliveries: [],

    hiddenTimeSlots: [],
    expandedRouteIds: [],

    hiddenDeliveryIds: [],

    hoveredDeliveryId: null,
    hoverInitiator: null, //'sidebar'/'map'/null,

    hoveredDriverRouteId: null,
    clickedDriverRouteId: null,

    settings: {
      showDriverPositions: true,
      showDeliveryOverlays: false,
      hoverEffectsMap: false,
      hoverEffectsSidebar: false,
    },

    // Tracking
    trackedRouteId: null,
    trackingPoints: [],
    hoveredSpeed: null,
    hoveredDateTime: null,
  }
}


export default {
  state: getDefaultState(),
  getters: {
    getSidebarCols: state => state.sideBarCols,
    getSelectedDate: state => state.selectedDate,
    getRoutes: state => state.routes,
    getRouteById: (state) => (id) => state.routes.find(r => r.id === id),
    getEmployeesForSelect: state => state.employees.map(e => {
      return {
        label: e.fullName,
        value: e.id
      }
    }),
    getRouteCount: state => state.routes.length,
    getDeliveries: state => state.deliveries,

    getTotalDistance: state => (state.routes || []).flatMap(r => r.totalDistance).reduce((prev, curr) => prev + curr) || 0,
    getTotalDuration: state => (state.routes || []).flatMap(r => r.totalDuration).reduce((prev, curr) => prev + curr) || 0,

    /**
     * Returns filtered deliveries for the map.
     * Filters out all deliveries without valid geocoding as we dont have coordinates for those.
     * @param state
     */
    getDeliveriesForMap: state => {
      return state.deliveries.filter(d => {
        return d.destination.geocoding !== null;
      });
    },

    getSelectedDeliveries: state => state.selectedDeliveries,
    hasSelectedAnyDeliveries: state => state.selectedDeliveries.length !== 0,
    isDeliverySelected: (state) => (id) => {
      const isSelected = state.selectedDeliveries.includes(id);

      return {
        isSelected: isSelected,
        number: isSelected ? state.selectedDeliveries.indexOf(id) + 1 : null
      }
    },

    /**
     * Returns the color and the number that will be used to show the delivery on the map.
     *
     * @param state
     * @returns {(function(*))|*}
     */
    getColorAndNumberOfDelivery: (state) => (id) => {
      let assignedRoute = findRouteByDeliveryId(state, id);

      // Not assigned to any Route yet
      if (assignedRoute === undefined) {
        return {
          color: null,
          number: null
        }
      }

      return {
        color: assignedRoute.hexColor,
        number: assignedRoute
          .steps
          .findIndex(s => s.delivery?.id === id)
      }
    },

    getColorForRoute: (state) => (id) => {
      return getColorForRouteFunc(state, id);
    },

    isRoutePublished: (state) => (id) => {
      return findRouteById(state, id)?.isPublished;
    },

    isDriverDistanceCheckEnabledForRoute: (state) => (id) => {
      return findRouteById(state, id)?.driverDistanceTresholdCheck;
    },

    getAllPossibleTimeslots: state => {
      return state.deliveries.map(d => d.timeSlot);
    },

    isTimeSlotHidden: (state) => ({startTime, endTime}) => {
      return state.hiddenTimeSlots.find(t => t.startTime === startTime && t.endTime === endTime) !== undefined;
    },

    isRouteExpanded: (state) => (routeId) => {
      return state.expandedRouteIds.includes(routeId);
    },
    hasRouteAnyDeliveries: (state) => (routeId) => {
      return getDeliveryIdsOfRoute(state, routeId).length > 0;
    },

    areAllDeliveriesOfRouteHidden: (state) => (routeId) => {
      let deliveryIdsOfRoute = getDeliveryIdsOfRoute(state, routeId);

      return deliveryIdsOfRoute.every(dId => state.hiddenDeliveryIds.includes(dId));
    },

    getRouteByDeliveryId: (state) => (deliveryId) => {
      return findRouteByDeliveryId(state, deliveryId);
    },

    getHoveredDriverRouteId: state => state.hoveredDriverRouteId,
    getHoveredDriverRoute: state => state.hoveredDriverRouteId === null ? null : state.routes.find(r => r.id === state.hoveredDriverRouteId),
    getHoveredDeliveryId: state => state.hoveredDeliveryId,
    isAnyDeliveryHovered: state => state.hoveredDeliveryId !== null,
    getHoveredRouteId: state => {
      if (state.hoveredDeliveryId === null)
        return null;

      return findRouteByDeliveryId(state, state.hoveredDeliveryId)?.id || null;
    },
    isDeliveryWithoutRouteHovered: state => {
      if (state.hoveredDeliveryId === null)
        return false;

      return findRouteByDeliveryId(state, state.hoveredDeliveryId) === undefined;
    },
    getHoverInitiatorIsMap: state => state.hoverInitiator === 'map',
    getHoverInitiatorIsSidebar: state => state.hoverInitiator === 'sidebar',

    getTotalDeliveriesCount: state => state.deliveries.length,
    getDeliveriesWithRouteCount: state => {
      const deliveryIds = state.routes.flatMap(r => {
        return getDeliveryIdsOfRoute(state, r.id);
      })
      return deliveryIds.length;
    },
    getHiddenDeliveryIds: state => state.hiddenDeliveryIds,
    getHiddenDeliveriesCount: state => state.hiddenDeliveryIds.length,
    getDeliveriesWithoutGeocodingCount: state => state.deliveries.filter(d => !d.destination.geocoding).length,
    getInvalidDeliveries: state => state.deliveries.filter(d => !d.destination.geocoding || !d.destination.geocoding.isPrecise || d.destination.zoneId === null),
    getInvalidDeliveriesCount: state => state.deliveries.filter(d => !d.destination.geocoding || !d.destination.geocoding.isPrecise || d.destination.zoneId === null).length,

    getInitialRoutesAndDeliveriesLoaded: state => state.initialRoutesLoaded === true && state.initialDeliveriesLoaded === true,

    getDriverPositionsWithColor: state => {
      return state
        .routes
        .filter(r => r.isEnRoute && r.lastDriverPosition !== null)
        .map(r => {
          let totalDeliveries = r.steps.filter(s => s.delivery).length;
          let deliveredDeliveries = r.steps.filter(s => s.delivery && s.delivery.deliveredAt).length;

          return {
            routeId: r.id,
            position: r.lastDriverPosition,
            color: getColorForRouteFunc(state, r.id),
            driverName: r.driver,
            totalDeliveries: totalDeliveries,
            deliveredDeliveries: deliveredDeliveries
          }
        })
    },
    getClickedDriverRouteId: state => state.clickedDriverRouteId,
    getShowDriverPositions: state => state.settings.showDriverPositions,
    getShowDeliveryOverlays: state => state.settings.showDeliveryOverlays,


    getTrackedRouteId: state => state.trackedRouteId,
    getTrackingPoints: state => state.trackingPoints,
    getIsTrackingAnyRoute: state => state.trackedRouteId !== null,
    getHoveredSpeed: state => state.hoveredSpeed,
    getHoveredDateTime: state => state.hoveredDateTime,

    getNextAvailableRouteColor: state => {
      return getColorByRouteIndex(state.routes.length);
    }
  },
  mutations: {
    setInitialState(state) {
      Object.assign(state, getDefaultState());
    },

    setTrackedRouteId(state, routeId) {
      state.trackedRouteId = routeId;
    },
    setTrackingPoints(state, trackingPoints) {
      state.trackingPoints = trackingPoints;
    },
    setHoveredSpeed(state, speed) {
      state.hoveredSpeed = speed;
    },
    setHoveredDateTime(state, dateTime) {
      state.hoveredDateTime = dateTime;
    },

    setClickedDriverRouteId(state, routeId) {
      state.clickedDriverRouteId = routeId
    },
    hideDeliveries(state, deliveryIds) {
      // using new Set to remove duplicated
      state.hiddenDeliveryIds = [...new Set(state.hiddenDeliveryIds.concat(deliveryIds))];
    },
    hideAllDeliveriesOfRoute(state, routeId) {
      let deliveryIds = getDeliveryIdsOfRoute(state, routeId);
      // Remove first to prevent hiding some twice.
      removeHiddenDeliveries(state, deliveryIds);
      state.hiddenDeliveryIds = state.hiddenDeliveryIds.concat(deliveryIds);
    },
    showAllDeliveriesOfRoute(state, routeId) {
      let deliveryIds = getDeliveryIdsOfRoute(state, routeId);
      removeHiddenDeliveries(state, deliveryIds);
    },
    showDeliveries(state, deliveryIds) {
      removeHiddenDeliveries(state, deliveryIds);
    },
    showAllDeliveries(state) {
      state.hiddenDeliveryIds = [];
      state.hiddenTimeSlots = [];
    },
    showDeliveriesWithoutRouteOnly(state) {
      let deliveryIds = state.deliveries.flatMap(d => d.id);
      let deliveriesWithRoutes = state.routes.flatMap(r => {
        return getDeliveryIdsOfRoute(state, r.id);
      })

      state.hiddenDeliveryIds = deliveryIds.filter(dId => {
        return deliveriesWithRoutes.includes(dId);
      });

      // Hide again deliveries from hidden timeslots
      let getHiddenTimeslots = state.hiddenTimeSlots;
      let deliveriesAffected = [];
      getHiddenTimeslots.forEach(t => {
        let deliveryIds = getDeliveriesByTimeSlotStartAndEndTime(state, t.startTime, t.endTime).flatMap(d => d.id);
        deliveriesAffected = deliveriesAffected.concat(deliveryIds);
      })
      state.hiddenDeliveryIds = state.hiddenDeliveryIds.concat(deliveriesAffected);
    },
    showFreshlyDeliveriesOnly(state) {
      state.hiddenDeliveryIds = state.deliveries
          .filter(delivery => delivery.subscriptionId === null)
          .flatMap(d => d.id);


      // Hide again deliveries from hidden timeslots
      let getHiddenTimeslots = state.hiddenTimeSlots;
      let deliveriesAffected = [];
      getHiddenTimeslots.forEach(t => {
        let deliveryIds = getDeliveriesByTimeSlotStartAndEndTime(state, t.startTime, t.endTime).flatMap(d => d.id);
        deliveriesAffected = deliveriesAffected.concat(deliveryIds);
      })
      state.hiddenDeliveryIds = state.hiddenDeliveryIds.concat(deliveriesAffected);
    },
    selectDelivery(state, deliveryId) {
      state.selectedDeliveries.includes(deliveryId)
        ? state.selectedDeliveries = state.selectedDeliveries.filter(id => id !== deliveryId)
        : state.selectedDeliveries.push(deliveryId);
    },
    clearSelectedDeliveries(state) {
      state.selectedDeliveries = [];
    },

    selectAllVisibleDeliveries(state) {
      state.selectedDeliveries = [];

      let shownDeliveryIds = state.deliveries
        .flatMap(d => d.id)
        .filter(deliveryId => !state.hiddenDeliveryIds.includes(deliveryId));


      state.selectedDeliveries = shownDeliveryIds;
    },

    hideOrShowTimeSlot(state, {startTime, endTime}) {
      const deliveriesAffected = getDeliveriesByTimeSlotStartAndEndTime(state, startTime, endTime).flatMap(d => d.id);
      const isTimeSlotHidden = state.hiddenTimeSlots.find(t => t.startTime === startTime && t.endTime === endTime) !== undefined;

      if (isTimeSlotHidden) {
        state.hiddenTimeSlots = state.hiddenTimeSlots.filter(t => !(t.startTime === startTime && t.endTime === endTime));
        removeHiddenDeliveries(state, deliveriesAffected);
      } else {
        state.hiddenTimeSlots.push({
          startTime: startTime,
          endTime: endTime,
        });
        state.hiddenDeliveryIds = state.hiddenDeliveryIds.concat(deliveriesAffected);
      }
    },
    clearHiddenTimeSlots(state) {
      state.hiddenTimeSlots = [];
    },

    expandOrCollapseRoute(state, routeId) {
      state.expandedRouteIds.includes(routeId)
        ? state.expandedRouteIds = state.expandedRouteIds.filter(id => id !== routeId)
        : state.expandedRouteIds.push(routeId);
    },
    collapseAllRoutes(state) {
      state.expandedRouteIds = [];
    },
    expandAllRoutes(state) {
      state.expandedRouteIds = [...new Set(state.routes.flatMap(r => r.id))];
    },
    setSidebarCols(state, cols) {
      state.sideBarCols = cols;
    },
    setRoutes(state, routes) {
      state.routes = routes;
      state.initialRoutesLoaded = true;
    },

    setRouteSteps(state, {routeId, steps}) {
      state.routes.find(r => r.id === routeId).steps = steps;
    },
    setDeliveries(state, deliveries) {
      state.deliveries = deliveries;
      state.initialDeliveriesLoaded = true;
    },
    setEmployees(state, employees) {
      state.employees = employees;
    },
    setSelectedDate(state, date) {
      state.selectedDate = date;
    },
    setHoveredDeliveryId(state, {deliveryId, initiator}) {
      state.hoveredDeliveryId = deliveryId;
      state.hoverInitiator = initiator;
    },
    clearHoveredDeliveryId(state) {
      state.hoveredDeliveryId = null;
      state.hoverInitiator = null;
    },
    setHoveredDriverRouteId(state, {driverRouteId, initiator}) {
      state.hoveredDriverRouteId = driverRouteId;
      state.hoverInitiator = initiator;
    },
    clearHoveredDriverRouteId(state) {
      state.hoveredDriverRouteId = null;
      state.hoverInitiator = null;
    },
    setHoverEffectOnMap(state, bool) {
      state.settings.hoverEffectsMap = bool;
    },
    setHoverEffectOnSidebar(state, bool) {
      state.settings.hoverEffectsSidebar = bool;
    },
    setShowDriverPositions(state, bool) {
      state.settings.showDriverPositions = bool;
    },
    setShowDeliveryOverlays(state, bool) {
      state.settings.showDeliveryOverlays = bool;
    },

    setRouteDepartureTime(state, {routeId, departure}) {
      state.routes.find(r => r.id === routeId).departure = departure;
    },

    replaceRoute(state, {routeId, data}) {
      state.routes = state.routes.map(r => r.id === routeId ? data : r);
    },
  },
  actions: {
    fetchAllRoutes({commit, state}, withLoadingIndicator = true) {
      return RouteRepository.getAllByDate(state.selectedDate, withLoadingIndicator).then((res) => {
        commit('setRoutes', res.data.data.routes);
        commit('routeManager/automaticPlanning/setPlanningRouteJobId', res.data.data.planningRouteJobId, {root: true});
      });
    },
    fetchDeliveries({commit, state}) {
      return DeliveryRepository.getActiveByDay(state.selectedDate).then((res) => {
        commit('setDeliveries', res.data.data);
      });
    },
    fetchEmployees({commit}) {
      return MemberRepository.getAllActiveEmployees().then((res) => {
        commit('setEmployees', res.data.data);
      });
    },
    createRouteForSelectedDeliveries({commit, dispatch, getters}) {
      return RouteRepository.create({
        departure: vue.$date(getters.getSelectedDate).set('hour', 10).set('minute', 0).format('YYYY-MM-DD HH:mm'),
        deliveries: getters.getSelectedDeliveries,
        hexColor: getters.getNextAvailableRouteColor
      }).then(() => {
        dispatch('fetchAllRoutes');
        commit('clearSelectedDeliveries');
      })
    },
    deleteRoute({dispatch}, routeId) {
      return RouteRepository.delete(routeId).then(() => {
        dispatch('fetchAllRoutes');
      })
    },

    addSelectedDeliveriesToRoute({state, getters, dispatch, commit}, routeId) {
      // DeliveryIds that are already on the selecterd route
      let existingDeliveriesOnRoute = getDeliveryIdsOfRoute(state, routeId);

      // We remove the deliveries that are already added and going to be added again so that they are added at the end.
      // This allows us as well to select all existing deliveries again, even add new ones and change the sorting of them in one go
      // See Ticket: https://luxcaddy.atlassian.net/browse/LUX-1670
      getters.getSelectedDeliveries.forEach(d => {
        if (existingDeliveriesOnRoute.includes(d)) {
          existingDeliveriesOnRoute = existingDeliveriesOnRoute.filter(ed => ed !== d);
        }
      });

      // Now we concatenate them. We already made sure in the previous line that there are no duplicates.
      let newDeliveries = existingDeliveriesOnRoute.concat(getters.getSelectedDeliveries);

      return RouteRepository.setDeliveries(routeId, newDeliveries).then(() => {
        dispatch('fetchAllRoutes');
        commit('clearSelectedDeliveries');
      })
    },
    setDeliveries({dispatch, commit}, {routeId, deliveryIds}) {
      return RouteRepository.setDeliveries(routeId, deliveryIds).then(() => {
        dispatch('fetchAllRoutes');
        commit('clearSelectedDeliveries');
      })
    },
    removeDeliveryFromRoute({ dispatch, commit}, {routeId, deliveryId}) {

      return RouteRepository.removeDelivery(routeId, deliveryId).then(() => {
        dispatch('fetchAllRoutes');
        commit('showAllDeliveriesOfRoute', routeId);
      })
    },
    updateRoute({dispatch}, {routeId, driverId, departure}) {
      return RouteRepository.update(routeId, {
        driver: driverId,
        departure: departure
      }).then(() => {
        dispatch('fetchAllRoutes');
      })
    },
    publishRoute({dispatch}, routeId) {
      return RouteRepository.publish(routeId).then(() => {
        dispatch('fetchAllRoutes');
      });
    },
    enableDriverDistanceTresholdCheck({dispatch}, routeId) {
      return RouteRepository.enableDriverDistanceTresholdCheck(routeId).then(() => {
        dispatch('fetchAllRoutes');
      });
    },
    disableDriverDistanceTresholdCheck({dispatch}, routeId) {
      return RouteRepository.disableDriverDistanceTresholdCheck(routeId).then(() => {
        dispatch('fetchAllRoutes');
      });
    },

    unpublishRoute({dispatch}, routeId) {
      return RouteRepository.unpublish(routeId).then(() => {
        dispatch('fetchAllRoutes');
      });
    },
    printRoute(_, routeId) {
      return RouteRepository.print(routeId);
    },

    /**
     * Updates a route and its tracking data concurrently.
     *
     * @param commit
     * @param routeId
     * @returns {Promise<TResult1>}
     */
    fetchRouteTrackingData({commit, getters}, routeId) {
      let routeRequest = RouteRepository.getSingle(routeId, false);
      let trackingPointRequest = RouteRepository.tracking.fetchTrackingDataForRoute(routeId);

      return axios
        .all([routeRequest, trackingPointRequest])
        .then(axios.spread((routeData, trackingPointData) => {

          commit('replaceRoute', {
            routeId: routeId,
            data: routeData.data.data
          });

          // Fallback for when tracking is disabled while the request is still running.
          if (getters.getIsTrackingAnyRoute) {
            commit('setTrackingPoints', trackingPointData.data.data.trackingData);
          } else {
            commit('setTrackingPoints', []);
          }
        }));
    }
  },
}