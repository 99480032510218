<template>
  <div>
    <card class="mb-4" type="light">
      <div class="grid items-end grid-cols-1 lg:grid-cols-3 2xl:grid-cols-7 mb-3 gap-x-6">
        <select-element
          v-model="group"
          :enable-search="false"
          :occupy-validation-space="false"
          :options="getGroupOptions"
          active-in-read-only
          label="Groupé par"
        />
        <date-range-picker-element
          v-model="dateRange"
          class="2xl:col-span-2"
          end-date-end-of-day
          label="Date entre"
          with-time
        />
        <checkbox-element
          v-model="queryByDeliveryDate"
          :occupy-validation-space="false"
          active-in-read-only
          label="Par date livraison"
        />
        <checkbox-element
          v-model="queryWithCreditNotes"
          :occupy-validation-space="false"
          active-in-read-only

          label="Inclure notes de credit"
        />
        <select-element
          v-model="selectedShortcut"
          :enable-search="false"
          :occupy-validation-space="false"
          :options="getShortCuts"
          active-in-read-only
          label="Raccourcis"
          @input="setShortCut"
        />
        <div class="flex gap-x-2 mt-4">
          <button-element size="small" type="primary" @click="applyFilters">
            <fa-icon fixed-width icon="check"/>
          </button-element>
          <button-element size="small" type="danger" @click="resetFilters">
            <fa-icon fixed-width icon="times"/>
          </button-element>
        </div>
      </div>

    </card>

    <card>
      <select-element
        v-model="displayData"
        :enableSearch="false"
        :options="getDisplayOptions"
        active-in-read-only
        label="Données"
      />

      <vue-apex-charts
        v-if="loaded"
        :options="getChartOptions"
        :series="getSeries"
        height="700px"
      />
    </card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import OrderRepository from "@/repositories/OrderRepository";
import Card from "@/components/elements/cards/Card";
import CheckboxElement from "../../elements/checkboxes/CheckboxElement";
import ButtonElement from "@/components/elements/buttons/ButtonElement.vue";
import DateRangePickerElement from "@/components/elements/forms/elements/base/dateRange/DateRangePickerElement.vue";
import dayjs from "dayjs";
import SelectElement from "@/components/elements/forms/elements/select/SelectElement";

export default {
  components: {
    SelectElement,
    DateRangePickerElement,
    ButtonElement,
    CheckboxElement,
    Card,
    VueApexCharts
  },
  name: "OrderItemStatsGraph",
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    groupBy: {
      type: String,
      default: 'month',
    },
    defaultDisplayOption: {
      type: Number,
      default: 0,
    },
    graphStart: {
      type: Date,
    },
    graphEnd: {
      type: Date
    },
  },
  data: () => ({
    orderStats: [],
    loaded: false,
    displayData: null,
    queryByDeliveryDate: false,
    queryWithCreditNotes: false,
    selectedShortcut: null,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    group: null,

    minDateShownOnChart: null,
    maxDateShownOnChart: null
  }),
  created() {
    this.dateRange.startDate = dayjs(this.graphStart).format('DD.MM.YYYY HH:mm:ss');
    this.dateRange.endDate = dayjs(this.graphEnd).format('DD.MM.YYYY HH:mm:ss');

    this.group = this.groupBy;
    this.displayData = this.getDisplayOptions[this.defaultDisplayOption].value;
    this.fetchOrderStats();
  },
  methods: {
    formatAmount(amount) {
      return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(amount);
    },
    fetchOrderStats() {
      this.getChartOptions.tooltip.x.format = this.getTooltipFormat;

      let queryFilters = [...this.filters];

      queryFilters.push(
        {
          filterKey: this.queryByDeliveryDate ? 'deliveryPeriod' : 'createdPeriod',
          filterValue: this.dateRange.startDate + ',' + this.dateRange.endDate
        }
      );

      if (this.queryWithCreditNotes) {
        queryFilters.push(
          {
            filterKey: 'includeCreditNotes',
            filterValue: this.queryWithCreditNotes
          }
        );
      }


      this.loaded = false;
      return OrderRepository.getOrderStats(this.group, queryFilters).then((res) => {
        this.orderStats = res.data.data;
        this.loaded = true;

        if (this.group === 'week') {
          this.minDateShownOnChart = 1;
          this.maxDateShownOnChart = 52;
        } else {

          this.minDateShownOnChart = dayjs(this.dateRange.startDate, 'DD.MM.YYYY HH:mm:ss').valueOf();
          this.maxDateShownOnChart = dayjs(this.dateRange.endDate, 'DD.MM.YYYY HH:mm:ss').valueOf();

        }
      })
    },
    setShortCut() {
      Object.keys(this.selectedShortcut).forEach((property) => {
        this.$set(this, property, this.selectedShortcut[property]);
      });
    },

    resetFilters() {
      this.dateRange.startDate = dayjs(this.graphStart).format('DD.MM.YYYY HH:mm:ss');
      this.dateRange.endDate = dayjs(this.graphEnd).format('DD.MM.YYYY HH:mm:ss');

      this.group = this.groupBy;
      this.displayData = this.getDisplayOptions[this.defaultDisplayOption].value;

      this.queryByDeliveryDate = false;
      this.queryWithCreditNotes = false;

      this.fetchOrderStats();
    },
    applyFilters() {
      this.fetchOrderStats();
      this.$emit('filtersApplied');
    },

  },
  computed: {
    getShortCuts() {
      return [
        {
          label: 'Année courante (' + dayjs().startOf('year').format('YYYY') + ')',
          value: {
            dateRange: {
              startDate: dayjs().startOf('year').format('DD.MM.YYYY HH:mm:ss'),
              endDate: dayjs().endOf('year').endOf('day').format('DD.MM.YYYY HH:mm:ss'),
            },
            group: 'month'
          }
        },
        {
          label: 'Année précédente (' + dayjs().startOf('year').subtract(1, 'year').format('YYYY') + ')',
          value: {
            dateRange: {
              startDate: dayjs().subtract(1, 'year').startOf('year').format('DD.MM.YYYY HH:mm:ss'),
              endDate: dayjs().subtract(1, 'year').endOf('year').endOf('day').format('DD.MM.YYYY HH:mm:ss'),
            },
            group: 'month'
          }
        },
        {
          label: 'Mois courant (' + dayjs().startOf('month').format('MMMM YYYY') + ')',
          value: {
            dateRange: {
              startDate: dayjs().startOf('month').format('DD.MM.YYYY HH:mm:ss'),
              endDate: dayjs().endOf('month').endOf('day').format('DD.MM.YYYY HH:mm:ss'),
            },
            group: 'day'
          }
        },
        {
          label: 'Mois précédent (' + dayjs().startOf('month').subtract(1, 'month').format('MMMM YYYY') + ')',
          value: {
            dateRange: {
              startDate: dayjs().subtract(1, 'month').startOf('month').format('DD.MM.YYYY HH:mm:ss'),
              endDate: dayjs().subtract(1, 'month').endOf('month').endOf('day').format('DD.MM.YYYY HH:mm:ss'),
            },
            group: 'day'
          }
        },
        {
          label: 'Tous les temps',
          value: {
            dateRange: {
              startDate: dayjs('2007-01-01').format('DD.MM.YYYY HH:mm:ss'),
              endDate: dayjs().add(1, 'year').startOf('year').endOf('day').format('DD.MM.YYYY HH:mm:ss'),
            },
            group: 'year'
          }
        }
      ]
    },

    getChartOptions() {
      const vm = this;

      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          }
        },
        xaxis: {
          title: {
            text: this.getXaxisText
          },
          type: this.getXaxisType,
          min: this.minDateShownOnChart,
          max: this.maxDateShownOnChart,
        },
        yaxis: {
          title: {
            text: this.displayData.name
          },
          labels: {
            show: true,
            formatter: function (val) {
              let sign = '';
              if (vm.displayData.resultProperty !== "totalQuantity") {
                sign = ' €';
              }

              return val + '' + sign;
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#7E9036', '#366390'],
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            format: 'dd MMMM',
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 6,
          },
        },
        title: {
          text: this.getTitle,
          floating: false,
          align: 'center',
          style: {
            fontSize: '14px',
          }
        },
      }
    },
    getTitle() {
      if (!this.displayData.supportsTitle) {
        return '';
      }
      let hasTypeProductFilter = this.filters.find(p => p.filterKey === 'type' && p.filterValue==='Product');
      let ordersTitle = this.getOrderInfo.quantity + ' Commandes ' + this.getOrderInfo.totalAmount + ' (AVG: ' + this.getOrderInfo.averageAmount + ')' + ' (AVG Item Qty: ' + this.getOrderInfo.averageItemQuantity + ')';
      // on product stats tab we do not display invoice details
      let invoiceTitle = hasTypeProductFilter ? ' ' : this.getInvoiceInfo.quantity + ' Factures: ' + this.getInvoiceInfo.totalAmount + ' (AVG: ' + this.getInvoiceInfo.averageAmount + ')';

      return [ordersTitle,invoiceTitle];
    },
    getDisplayOptions() {
      return [
        {
          label: 'Quantité',
          value: {
            name: 'Quantité',
            resultProperty: 'totalQuantity',
            isCurrency: false,
            supportsTitle: false,
          }
        },
        {
          label: 'Total TTC',
          value: {
            name: 'Total TTC €',
            resultProperty: 'totalPriceWithVat',
            isCurrency: true,
            supportsTitle: true,
          }
        },
        {
          label: 'Total htva',
          value: {
            name: 'Total htva €',
            resultProperty: 'totalPrice',
            isCurrency: true,
            supportsTitle: true,
          }
        },
        {
          label: 'Montant moyen',
          value: {
            name: 'Montant moyen €',
            resultProperty: 'averageValue',
            isCurrency: true,
            supportsTitle: false,
          }
        },
      ]
    },
    getTooltipFormat() {
      if (this.group === 'month') {
        return 'MMMM yyyy';
      }

      if (this.group === 'year') {
        return 'yyyy';
      }
      return 'd MMMM yyyy';
    },

    getXaxisText() {
      if (this.group === 'week') {
        return 'Week';
      }

      return 'Date';
    },
    getXaxisType() {
      if (this.group === 'week') {
        return 'text';
      }


      return 'datetime';
    },

    getGroupOptions() {
      return [
        {label: 'jour', value: 'day'},
        {label: 'semaine', value: 'week'},
        {label: 'mois', value: 'month'},
        {label: 'année', value: 'year'},
      ];
    },
    getMappedData() {
      return Object.entries(this.orderStats).map(oE => {
        return {
          date: oE[0],
          data: oE[1]
        }
      });
    },
    getOrderInfo() {
      const amountOfOrders = this.getMappedData.reduce((a, o) => a + (o.data.orders?.totalQuantity || 0), 0);
      let totalAmount = this.getMappedData.reduce((accumulator, object) => {
        if (object.data.orders === undefined) {
          return accumulator;
        }

        return accumulator + parseInt(object.data.orders[this.displayData.resultProperty]);
      }, 0);

      const amountOfOrdersItems = this.getMappedData.reduce((a, o) =>
          a + parseInt(o.data.orders?.totalItemsPerOrder || 0, 10),
      0);

      let averageAmount = totalAmount / amountOfOrders;
      let averageItemQuantity = (amountOfOrdersItems / amountOfOrders).toFixed(2);

      return {
        quantity: amountOfOrders,
        totalAmount: this.displayData.isCurrency ? this.formatAmount(totalAmount) : totalAmount,
        averageAmount: this.displayData.isCurrency ? this.formatAmount(averageAmount) : averageAmount,
        averageItemQuantity: averageItemQuantity
      }
    },
    getInvoiceInfo() {
      const amountOfInvoices = this.getMappedData.reduce((a, o) => a + (o.data.invoices?.totalQuantity || 0), 0);
      let totalAmount = this.getMappedData.reduce((accumulator, object) => {
        if (object.data.invoices === undefined) {
          return accumulator;
        }
        return accumulator + parseInt(object.data.invoices[this.displayData.resultProperty]);
      }, 0);

      let averageAmount = totalAmount / amountOfInvoices;

      return {
        quantity: amountOfInvoices,
        totalAmount: this.displayData.isCurrency ? this.formatAmount(totalAmount) : totalAmount,
        averageAmount: this.displayData.isCurrency ? this.formatAmount(averageAmount) : averageAmount
      }
    },
    getSeries() {
      const orderData = this.getMappedData.map((d) => {
        return {
          x: d.date,
          y: (d.data.orders !== undefined ? d.data.orders[this.displayData.resultProperty] : 0)
        }
      });

      const invoiceData = this.getMappedData.map((d) => {
        return {
          x: d.date,
          y: d.data.invoices !== undefined ? d.data.invoices[this.displayData.resultProperty] : 0
        }
      });


      return [
        {
          name: "Commandes",
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          data: orderData
        },
        {
          name: "Factures",
          data: invoiceData
        },
      ]
    }
  }

}
</script>