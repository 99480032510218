<template>
    <list-table
            :key="getTableKey"
            :columns="columns"
            :filters="filters"
            :pagination="true"
            :repository-func="repositoryFunc"
            :title="title"
            identifier="upcomingDeliveriesTable"
            show-filters-immediately
    >

        <template v-slot:destination-template="{ row }">
            <div class="font-bold text-sm mb-1">
                {{ row.fullName }}
            </div>
            <div class="text-xs">
                <p>{{ row.street }}, {{ row.postalCode }} {{ row.city }},
                    {{ row.country | toCountryNameString }}</p>
            </div>
        </template>

        <template v-slot:timeslot-template="{ row }">
            <time-slot-as-string :from="$date(row.timeSlot.startDate)" :to="$date(row.timeSlot.endDate)"
                                 :zone="row.timeSlot.zoneId"/>
        </template>


        <template v-slot:precise-geocoding-template="{ row }">
            <geocoding-precision-status
                    :geocoding="row.geocoding"
                    small
            />
        </template>

        <template v-slot:member-quick-action-template="{ row }">
            <member-quick-action-modal :id="row.memberId"/>
        </template>
    </list-table>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import GeocodingPrecisionStatus
    from "@/components/pages/Members/EditMember/Tabs/DeliveryContacts/Includes/GeocodingPrecisionStatus";
import MemberQuickActionModal from "@/components/pages/Members/Subcomponents/MemberQuickActionModal";

import SubscriptionRepository from "@/repositories/SubscriptionRepository";
import TimeSlotAsString from "@/components/global/TimeSlots/TimeSlotAsString.vue";
import {
    FILTER_BOOLEAN,
    FILTER_DATETIME_RANGE,
    FILTER_TEXT
} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
    name: "ShowUpcomingDeliveries",
    components: {
        TimeSlotAsString,
        MemberQuickActionModal,
        GeocodingPrecisionStatus,
        ListTable
    },
    computed: {
        // used as a workaround to render the table again when switching from a shortcut (today's orders, tomorrows order, ...) to the regular page,
        getTableKey() {
            return this.$route.name + '_' + Math.random();
        }
    },
    data() {
        return {
            title: 'Livraisons à venir',
            repositoryFunc: SubscriptionRepository.getPaginatedUpcomingDeliveries,
            filters: [
                {
                    caption: "Abonnements ID(s)",
                    filterKey: "subscriptionIds",
                    forceIsVisible: true,
                    small: true,
                },
                {
                    caption: "ID(s) ou Nom Membre",
                    filterKey: "memberIdsOrName",
                    forceIsVisible: true,
                    small: true,
                },
                {
                    filterKey: 'timeslot',
                    caption: "Date entre",
                    type: FILTER_DATETIME_RANGE,
                    forceIsVisible: true,
                },
                {
                    caption: "Zone",
                    filterKey: "zone",
                    type: FILTER_TEXT
                },
                {
                    caption: "Code postal",
                    filterKey: "postalCode",
                    type: FILTER_TEXT
                },
                {
                    caption: 'Actif',
                    filterKey: 'active',
                    type: FILTER_BOOLEAN,
                    small: true,
                    forceIsVisible: true,
                    defaultValue: 1
                },
            ],
            columns: [
                {
                    caption: 'Mem',
                    cellTemplate: 'member-quick-action-template'
                },
                {
                    caption: 'Subscription ID',
                    dataField: 'subscriptionId',
                    width: 0,
                    sort: {
                        dataField: 'subscriptionId',
                        default: 'desc'
                    }
                },
                {
                    caption: 'Destination',
                    cellTemplate: 'destination-template',
                },
                {
                    caption: 'Créneau',
                    cellTemplate: 'timeslot-template',
                    sort: {
                        dataField: 'timeSlot',
                    }
                },
                {
                    caption: 'Géo',
                    cellTemplate: 'precise-geocoding-template'
                },
            ]
        }
    },
}
</script>

