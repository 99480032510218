import Vue from "vue";
import VueRouter from "vue-router";
import {isScreenSizeBelowBreakpoint} from '@/helpers/screenWidthHelper';

import store from "../store/store";

import BrandRoutes from "./routes/BrandRoutes";
import MemberRoutes from "./routes/MemberRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import auth from "./middlewares/authMiddleware";
import ProductRoutes from "./routes/ProductRoutes";
import ProductCategoryRoutes from "./routes/ProductCategoryRoutes";
import StockHistoryRoutes from "@/router/routes/StockHistoryRoutes";
import CartRoutes from "./routes/CartRoutes";
import OrderRoutes from "./routes/OrderRoutes";
import FeeRoutes from "./routes/FeeRoutes";
import TagRoutes from "./routes/TagRoutes";
import DeliveryTimeSlotRoutes from "./routes/DeliveryTimeSlotRoutes";
import DeliveryPostalCodeRoutes from "./routes/DeliveryPostalCodeRoutes";
import RackRoutes from "./routes/RackRoutes";
import CampaignRoutes from "./routes/CampaignRoutes";
import FulfilmentRoutes from "./routes/touchInterface/FulfilmentRoutes";
import PickingRoutes from "./routes/touchInterface/PickingRoutes";
import StockRoutes from "@/router/routes/touchInterface/StockRoutes";
import ArrivalTemperatureRoutes from "@/router/routes/ArrivalTemperatureRoutes";
import RouteManagerRoutes from "@/router/routes/RouteManagerRoutes";
import DeliveryRoutes from "@/router/routes/DeliveryRoutes";
import InvoiceRoutes from "@/router/routes/InvoiceRoutes";
import DeviceRoutes from "@/router/routes/DeviceRoutes";
import SupplierOrderRoutes from "@/router/routes/SupplierOrderRoutes";
import SupplierCatalogRoutes from "@/router/routes/SupplierCatalogRoutes";
import DepositRoutes from "./routes/DepositRoutes";
import MemberHolidayRoutes from "@/router/routes/MemberHolidayRoutes";
import LogRoutes from "@/router/routes/LogRoutes";
import BlogRoutes from "@/router/routes/BlogRoutes";
import BannerRoutes from "@/router/routes/BannerRoutes";
import CategoryRoutes from "@/router/routes/CategoryRoutes";
import TaskRoutes from "@/router/routes/TaskRoutes";
import TaskRecurringRoutes from "@/router/routes/TaskRecurringRoutes";
import TouchInterfaceAccountRoutes from "@/router/routes/touchInterface/TouchInterfaceAccountRoutes";
import TimeClockEntryRoutes from "@/router/routes/TimeClockEntryRoutes";
import ProductReportRoutes from "@/router/routes/ProductReportRoutes";
import UnfulfilledItemsRoutes from "@/router/routes/UnfulfilledItemsRoutes";
import SupplierPreviewRoutes from "@/router/routes/SupplierPreviewRoutes";
import StatsRoutes from "@/router/routes/StatsRoutes";
import {TouchDeliveryRoutes} from "@/router/routes/touchInterface/DeliveryRoutes";
import ShortUrlRoutes from "./routes/ShortUrlRoutes";
import BookingAccountRoutes from "@/router/routes/BookingAccountRoutes";
import AccountingRoutes from "./routes/AccountingRoutes";
import TouchInterfaceRoutes from "@/router/routes/touchInterface/TouchInterfaceRoutes";
import Home from "@/components/pages/Landing/Home";
import PrintFulfilmentLabelRoutes from "@/router/routes/PrintLabelBarcodeRoutes";
import NoPermission from "@/components/pages/ErrorPages/NoPermission";
import {userHasPermission} from "@/helpers/permissionHelper";
import TestPage from "@/components/pages/TestPage/TestPage.vue";
import VehicleRoutes from "@/router/routes/VehicleRoutes";
import ReportQueriesRoutes from "@/router/routes/ReportQueriesRoutes";
import RolesRoutes from "@/router/routes/RolesRoutes";
import DepartmentRoutes from "@/router/routes/DepartmentRoutes";
import SubscriptionRoutes from "@/router/routes/SubscriptionRoutes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {path: '/tablet', redirect: {name: 'auth.login.barcode'}},
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        middleware: [auth],
        permissions: ['ADMIN_PANEL_ACCESS']
      }
    },
    {
      path: '/test',
      name: 'test',
      component: TestPage,
      meta: {
        middleware: [auth],
        permissions: ['ADMIN_PANEL_ACCESS']
      }
    },
    {
      path: '/no-permission',
      name: 'no-permission',
      component: NoPermission,
      meta: {
        title: 'Accès interdit',
        middleware: [auth]
      }
    },
    ...AuthRoutes,
    ...MemberRoutes,
    ...BrandRoutes,
    ...CategoryRoutes,
    ...ProductRoutes,
    ...ProductCategoryRoutes,
    ...CartRoutes,
    ...OrderRoutes,
    ...FeeRoutes,
    ...TagRoutes,
    ...DeliveryTimeSlotRoutes,
    ...DeliveryPostalCodeRoutes,
    ...RackRoutes,
    ...CampaignRoutes,
    ...ArrivalTemperatureRoutes,
    ...DeliveryRoutes,
    ...InvoiceRoutes,
    ...DeviceRoutes,
    ...SupplierOrderRoutes,
    ...SupplierCatalogRoutes,
    ...LogRoutes,
    ...BlogRoutes,
    ...BannerRoutes,
    ...TaskRoutes,
    ...TaskRecurringRoutes,
    ...DepositRoutes,
    ...MemberHolidayRoutes,
    ...TimeClockEntryRoutes,
    ...ProductReportRoutes,
    ...UnfulfilledItemsRoutes,
    ...SupplierPreviewRoutes,
    ...StatsRoutes,
    ...BookingAccountRoutes,
    ...ShortUrlRoutes,
    ...AccountingRoutes,
    ...PrintFulfilmentLabelRoutes,
    ...StockHistoryRoutes,
    ...VehicleRoutes,
    ...RolesRoutes,
    ...DepartmentRoutes,

    // Route Manager
    ...RouteManagerRoutes,

    // Touch Interface
    ...TouchInterfaceRoutes,
    ...FulfilmentRoutes,
    ...PickingRoutes,
    ...StockRoutes,
    ...TouchDeliveryRoutes,
    ...TouchInterfaceAccountRoutes,
    ...ReportQueriesRoutes,
    ...SubscriptionRoutes
  ],
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware)
    return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({...context, next: nextMiddleware});
  };
}

router.beforeEach(async (to, from, next) => {
  /**
   * Cancel all requests when changing route
   */
  if (store.getters['loading/isRunningRequest']) {
    store.dispatch('loading/cancelAllRequests');
  }

  /**
   * Remove all displayed forms when changing route
   */
  if (store.getters['forms/isAnyFormDisplayed']) {
    store.commit('forms/clearAllDisplayedForms');
  }

  /*
   * Automatically set page Title based on meta.title
   */
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  document.title = to.query?.readonly ? nearestWithTitle?.meta?.readOnly?.title || 'Luxcaddy Backoffice' : nearestWithTitle?.meta?.title || 'Luxcaddy Backoffice';

  /**
   * Read only mode.
   */
  if (to.meta.readOnly?.supported) {
    store.commit('userInterface/setReadOnlyMode', to.query?.readonly !== undefined);
  } else {
    store.commit('userInterface/setReadOnlyMode', false);
  }

  if (to.meta.closeSidebarOnNavigate) {
    store.commit('userInterface/closeSidebar');
  }

  /**
   * Close sidebar on mobile when route changes
   */
  if (isScreenSizeBelowBreakpoint('md')) {
    store.commit('userInterface/setSidebarState', false);
  }

  // Check if user has correct permissions.
  if (to.meta?.permissions !== undefined) {
    const permissions = to.meta.permissions || [];
    const readOnlyPermissions = to.meta.readOnly?.permissions || [];

    const userHasPermissions = userHasPermission(permissions, store.getters['authUser/getPermissions']);

    let userHasReadOnlyPermissions = false;
    if (readOnlyPermissions.length > 0) {
      userHasReadOnlyPermissions = userHasPermission(readOnlyPermissions, store.getters['authUser/getPermissions']);

    }

    if (!userHasPermissions && !userHasReadOnlyPermissions) {
      return next('/no-permission');
    }
  }

  /**
   * Apply any given middlewares.
   */
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({...context, next: nextMiddleware});
  }

  next();
});


export default router;