<template>
  <div class="inline-block">
    <delete-confirmation-modal
      ref="deleteConfirmationModal"
      :delete-button-label="hint"
      :delete-button-icon="'fa-' + icon"
      :title="confirmationOptions.title"
      @delete="$emit('click', $event)"
    >
      {{ confirmationOptions.text }}
    </delete-confirmation-modal>
    <button-element
      :disabled="disabled"
      :title="hint"
      inverse
      size="tableaction"
      type="danger"
      @click.stop="onClick"
    >
      <fa-icon v-if="showIcon" :icon="icon" fixed-width></fa-icon>
      <span
        v-if="customText"
        :class="{'ml-2': showIcon}"
      >
            {{ customText }}
        </span>
    </button-element>
  </div>
</template>

<script>
import ButtonElement from "../../../elements/buttons/ButtonElement";
import DeleteConfirmationModal from "../../../elements/modals/DeleteConfirmationModal";

export default {
  name: "ListTableButtonDelete",
  components: {DeleteConfirmationModal, ButtonElement},
  props: {
    hint: {
      type: String,
      required: false
    },
    confirmationOptions: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      default: "trash"
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    customText: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    onClick() {
      this.$refs.deleteConfirmationModal.openModal();
    }
  },
}
</script>

