import apiService from './Base/BaseRepository';

const url = '/route';

export default {
  /**
   * Fetches all routes.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },

  /**
   * Fetches all routes.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(
    perPage = 999,
    page = 1,
    sorts = [],
    filters = [],
    showLoadingIndicator = true) {
    return await apiService
      .setPagination(perPage, page)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/`, {showLoadingIndicator});
  },

  async getSelectOptionsForDate(
    date,
    perPage = 999,
    page = 1,
    showLoadingIndicator = true
  ) {
    return await apiService
      .setPagination(perPage, page)
      .setFilters([{
        filterKey: 'date',
        filterValue: date
      }])
      .get(`${url}/select-options`, {showLoadingIndicator});
  },

  getAllByDate(date, showLoadingIndicator) {
    return this.getPaginated(
      1000,
      1,
      [],
      [{
        filterKey: 'date', filterValue: date
      }],
      showLoadingIndicator
    );
  },

  async getSingle(routeId, showLoadingIndicator = true) {
    return await apiService.get(`${url}/${routeId}`, {showLoadingIndicator});
  },

  async update(routeId, payload) {
    return await apiService.patch(`${url}/${routeId}`, payload);
  },


  async startPlanningRequest(
    deliveryIds,
    vehicleIds,
    startDate,
    endDate,
    objectives
  ) {
    return await apiService.post(`${url}/planning`, {
      deliveries: deliveryIds,
      vehiclesId: vehicleIds,
      earliestDepartureDateTime: startDate,
      latestEndDateTime: endDate,
      objectives: objectives
    });
  },

  async cancelPlanningRequest(jobId) {
    return await apiService.post(`${url}/planning/${jobId}/cancel`);
  },

  async setDeliveries(routeId, deliveryIds) {
    return await apiService.patch(`${url}/${routeId}/setDeliveries`, {
      deliveries: deliveryIds,
    });
  },
  async removeDelivery(routeId, deliveryId) {
    return await apiService.patch(`${url}/${routeId}/removeDelivery`, {
      deliveryId: deliveryId,
    });
  },

  async publish(routeId) {
    return await apiService.patch(`${url}/${routeId}/publish`);
  },

  async enableDriverDistanceTresholdCheck(routeId) {
    return await apiService.patch(`${url}/${routeId}/enableDriverDistanceTresholdCheck`);
  },

  async disableDriverDistanceTresholdCheck(routeId) {
    return await apiService.patch(`${url}/${routeId}/disableDriverDistanceTresholdCheck`);
  },

  async unpublish(routeId) {
    return await apiService.patch(`${url}/${routeId}/unpublish`);
  },

  async print(routeId) {
    return await apiService.get(`${url}/${routeId}/print`);
  },
  async downloadRoutePdf(routeId) {
    return await apiService.downloadBlob(`${url}/${routeId}/downloadPDF`);
  },

  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },
  async delete(routeId) {
    return await apiService.delete(`${url}/${routeId}`);
  },


  tracking: {
    async fetchTrackingDataForRoute(routeId) {
      return await apiService.get(`${url}/${routeId}/trackingData`, {
        showLoadingIndicator: false
      });
    }
  },

  my: {
    async getRoutes() {
      return await apiService.get(`/my/routes`);
    }
  }
}
