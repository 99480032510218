<template>
  <div>
    <list-table
      ref="tableRef"
      :columns="columns"
      :repository-func="repositoryFunc"
      :title="title"
      create-event
      identifier="productBarcodesTable"
      @create="createButtonClicked"
    />

    <create-or-update-product-barcode
      ref="productBarcodeForm"
      :barcode-id="activeBarcodeId"
      :mode="mode"
      :product-id="productId"
      @change="onChange"
    />
  </div>
</template>

<script>
import ProductRepository from "../../../../../repositories/ProductRepository";
import CreateOrUpdateProductBarcode from "../../Forms/Barcodes/CreateOrUpdateProductBarcodeForm";
import ListTable from "../../../../listTable/ListTable";
import BarcodeRepository from "@/repositories/BarcodeRepository";

export default {
  name: "ManageProductBarcodes",
  components: {ListTable, CreateOrUpdateProductBarcode},
  props: {
    productId: {
      type: Number,
      required: true
    }
  },
  data() {
    const vm = this;

    return {
      mode: 'create',
      activeBarcodeId: null,

      title: 'Code barres',
      repositoryFunc: () => {
        return ProductRepository
          .barcodes
          .getForProduct(vm.productId);
      },
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'edit',
              hint: 'Modifier code barre',
              onClick: (row) => this.editButtonClicked(row),
            },
            {
              type: 'delete',
              hint: 'Supprimer code barre',
              confirmation: {
                title: 'Supprimer code barre?',
                text: 'Étes vous sure que vous voulez supprimer ce code barre?'
              },
              successText: 'Le code barre a été supprimé',
              deleteAction: (row) => ProductRepository
                .barcodes
                .delete(
                  parseInt(vm.$route.params.product),
                  row.id
                )
            },
            {
              type: 'delete',
              icon: 'print',
              hint: 'Imprimer code barre',
              confirmation: {
                title: 'Imprimer code barre?',
                text: 'Étes vous sure que vous voulez imprimer ce code barre? Il est envoyé à l\'imprimante d\'étiquettes dans le bureau de la secrétaire.'
              },
              successText: 'Le code barre a été imprimée.',
              deleteAction: (row) => BarcodeRepository
                .printBarcode(
                  row.code,
                  row.type
                )
            },
          ],
        },
        {
          caption: '#',
          dataField: 'id',
          width: 80,
        },
        {
          caption: 'Code barre',
          dataField: 'code',
        },
        {
          caption: 'Commentaire',
          dataField: 'comment',
        },
      ],
    }
  },
  methods: {
    onChange() {
      this.$refs.tableRef.refresh();
    },
    createButtonClicked() {
      this.mode = 'create';
      this.activeBarcodeId = null;
      this.$nextTick(() => {
        this.modalInstance.openModal();
      });
    },
    editButtonClicked(row) {
      this.mode = 'update';
      this.activeBarcodeId = row.id;
      this.$nextTick(() => {
        this.modalInstance.openModal();
      });
    },
  },
  computed: {
    modalInstance() {
      return this.$refs.productBarcodeForm.$refs.barcodeModal;
    },
    isInReadOnlyMode() {
      return this.$isReadOnly();
    },
  },
}
</script>

