import OrderRepository from "../../../repositories/OrderRepository";

const getDefaultState = () => {
  return {
    order: null,

    // Reference & Substitute
    referencedItemId: null,
    substituteReferencedItem: false,
    referencedItemQuantity: 0,
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getOrder: state => state.order,
    hasOrder: state => state.order !== null,
    getOrderItems: state => state.order?.items || [],
    getOrderId: state => state.order?.id || null,
    getMemberId: state => state.order?.memberId || null,
    getDeliveryTimeSlot: state => state.order?.delivery?.timeSlot,
    getInvoiceAddress: state => state.order?.member?.invoicingDetails || {},
    getInvoiceId: state => state.order?.invoiceId || null,
    getParentId: state => state.order?.parentOrderId || null,
    getSubscriptionId: state => state.order?.subscriptionId || null,
    getCustomerGeneralDetails: state => state.order?.member?.generalDetails || {},
    getDeliveryAddress: state => state.order?.delivery?.destination || {},
    isOrderFulfilmentFinalized: state => state.order?.fulfilmentStatus === "fulfilmentFinalized",
    getCustomerMessage: state => state.order?.customerMessage,
    getTotal: state => state.order?.total || {},
    getVatDetails: state => state.order?.total?.vatDetails || {},
    isOrderEditable: state => state.order?.isEditable || false,
    canCancel: state => state.order.canCancel,
    canFulfill: state => state.order.fulfilmentStatus === 'fulfilled',
    canConfirm: state => state.order.status === 'draft',
    getDeliveryInstructions: state => state.order?.delivery?.instruction || "",
    getProductsIdsWithQuantities: state => (state.order.items || []).map((p) => {
      return {
        id: p.orderableId,
        quantity: p.orderedQuantity,
        packedQuantity: p.packedQuantity,
        toBePackedQuantity: p.toBePackedQuantity,
      }
    }).filter(p => p.id !== null),
    getFeeIdsWithQuantities: state => (state.order.items || [])
      .filter(p => p.type === "Fee")
      .map(p => {
        return {
          id: p.orderableId,
          quantity: p.orderedQuantity,
          packedQuantity: p.packedQuantity,
          toBePackedQuantity: p.toBePackedQuantity,
        }
      }).filter(p => p.id !== null),


    isReferencingItem: state => state.referencedItemId !== null,
    getReferencedItemId: state => state.referencedItemId,
    getSubstituteReferencedItem: state => state.substituteReferencedItem,
    getReferencedItemQuantity: state => state.referencedItemQuantity,
  },
  mutations: {
    // When set, adding a product/fee/custom product will reference this item
    setReferencedItemId(state, {
      id = null,
      substituteReferencedItem = false,
      referencedItemQuantity = 0
    }) {
      state.referencedItemId = id;
      state.substituteReferencedItem = substituteReferencedItem;
      state.referencedItemQuantity = referencedItemQuantity;
    },

    setReferencedItemQuantity(state, quantity) {
      if (state.referencedItemId !== null) {
        state.referencedItemQuantity = quantity;
      }
    },

    setOrderData(state, payload) {
      state.order = payload;
    },
    clearOrderData(state) {
      state.order = null;
    },
    setProductQuantity(state, payload) {
      let itemInOrder = state.order.items.find(p => p.orderableId === payload.productId);

      if (itemInOrder) {
        return itemInOrder.toBePackedQuantity = payload.toBePackedQuantity;
      }
    },
    setFeeQuantity(state, payload) {
      let feeInOrder = state.order.items.find(i => i.orderableId === payload.feeId && i.type === "Fee");

      if (feeInOrder) {
        return feeInOrder.toBePackedQuantity = payload.toBePackedQuantity;
      }
    },
  },
  actions: {
    fetchOrder({commit}, payload) {
      const showLoadingIndicator = payload.showLoadingIndicator === undefined ? true : payload.showLoadingIndicator;

      return OrderRepository.getSingle(payload.orderId, showLoadingIndicator).then((res) => {
        commit('setOrderData', res.data.data);
      });
    },
    addItemToOrder({getters}, payload) {
      const itemId = payload.itemId;
      const reference = payload?.reference || null;
      const useBuyingPriceWithAddOn = payload?.useBuyingPriceWithAddOn || false;
      const substituteReferencedItem = payload?.substituteReferencedItem || false;

      // If we are substituting, take the quantity from the substituted item
      const quantity = substituteReferencedItem
        ? getters.getReferencedItemQuantity
        : payload.quantity;


      return OrderRepository.addItem(
        getters.getOrderId,
        itemId,
        quantity,
        reference,
        substituteReferencedItem,
        useBuyingPriceWithAddOn,
        true,
        true,

      );
    },
    addOrChangeItemQuantity({getters}, payload) {
      const showLoadingIndicator = payload.showLoadingIndicator === undefined ? true : payload.showLoadingIndicator;
      const itemId = payload.itemId;
      const itemInOrder = getters.getOrderItems.find(i => i.orderableId === itemId);
      const quantity = payload.toBePackedQuantity;
      const useBuyingPriceWithAddOn = payload?.useBuyingPriceWithAddOn || false;

      if (itemInOrder) {
        let requestBody = {
          price: itemInOrder.price,
          vat: itemInOrder.vat,
          toBePackedQuantity: quantity,
          packedQuantity: itemInOrder.packedQuantity
        };
        return OrderRepository.editItem(getters.getOrderId, itemInOrder.id, requestBody, showLoadingIndicator, true);
      }
      return OrderRepository.addItem(getters.getOrderId, itemId, quantity, null, showLoadingIndicator, useBuyingPriceWithAddOn, true);
    },
    addFeeToOrder({getters}, payload) {
      return OrderRepository.addFeeItem(getters.getOrderId, payload, true, true);
    },
    addPackageItem({getters}, payload) {
      return OrderRepository.addPackageItem(getters.getOrderId, payload, true, true);
    },
    addOrChangeFeeQuantity({getters}, payload) {
      const showLoadingIndicator = payload.showLoadingIndicator === undefined ? true : payload.showLoadingIndicator;
      const feeId = payload.feeId;
      const feeInOrder = getters.getOrderItems.find(i => i.orderableId === feeId && i.type === "Fee");
      const quantity = payload.toBePackedQuantity;

      if (feeInOrder) {
        if (quantity === 0)
          return OrderRepository.removeItem(getters.getOrderId, feeInOrder.id, showLoadingIndicator, true);

        let requestBody = {
          price: feeInOrder.price.amount,
          vat: feeInOrder.price.vat,
          toBePackedQuantity: quantity,
          packedQuantity: feeInOrder.packedQuantity
        };

        return OrderRepository.editItem(getters.getOrderId, feeInOrder.id, requestBody, showLoadingIndicator, true);
      }
      return OrderRepository.addFeeItem(getters.getOrderId, {
        feeId: feeId,
        toBePackedQuantity: payload.toBePackedQuantity
      }, showLoadingIndicator, true);
    },
    refreshOrder({dispatch, getters}, showLoadingIndicator = true) {
      if (getters.getOrderId !== null) {
        dispatch('fetchOrder', {orderId: getters.getOrderId, showLoadingIndicator});
      }
    },
  },
}