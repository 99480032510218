import apiService from './Base/BaseRepository';

const url = '/barcodes';

export default {
  async printBarcode(barcode, type) {
    return await apiService
      .post(`${url}/print`, {barcode, type});
  },
}
