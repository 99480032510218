<template>
  <div>
    <list-table
      :columns="columns"
      :create-button="false"
      :filters="filters"
      :pagination="true"
      :repository-export-func="exportRepositoryFunc"
      :repository-func="repositoryFunc"
      :select-values="selectValues"
      :title="title"
      enable-totals
      export-button
      identifier="supplierSalesStatsTable"
      selectable
      sub-key="items"
      async-export-button
      :async-repository-export-func="asyncExportRepositoryFunc"
    >
    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import OrderRepository from "@/repositories/OrderRepository";
import {centsToEuroString, intToPercentageString} from "@/helpers/mathHelper";
import {supplierSalesStatsFilters} from "@/helpers/listTableModels/ListTableProductOrderStatsFilters";
import {FILTER_DATETIME_RANGE} from "@/components/listTable/includes/controls/filters/availableFilters";

export default {
  name: "SupplierSalesStats",
  components: {ListTable},
  data() {
    return {
      title: 'Statistiques ventes par fournisseur',
      selectValues: supplier => {
        return {
          supplierId: supplier.supplierId
        };
      },
      columns: [
        {
          caption: "Actions",
          buttons: [
            {
              type: 'show',
              hint: 'Voir details',
              route: (row) => ({
                name: 'stats.supplier-sales-details',
                params: {supplier: row.supplierId},
                query:  this.formatFiltersAsQuery( row)

              }),
            }
          ]
        },
        {
          caption: '#',
          dataField: 'supplierId',
          sort: {
            dataField: 'supplierId'
          }
        },
        {
          caption: 'Societe',
          dataField: 'supplierName',
          sort: {
            dataField: 'supplierName'
          }
        },
        {
          caption: 'Prix V. TTC',
          dataType: 'euro',
          calculateCellValue: (row) => centsToEuroString(row.totalSellingPriceWithVat),
          sort: {
            dataField: 'totalSellingPriceWithVat',
          },
          totals: {
            sum: "totalSellingPriceWithVat",
            formatValue: (value) => centsToEuroString(value),
            backend: {
              key: 'sellingPriceWithVAT'
            }
          },
        },
        {
          caption: 'Prix V. HTVA',
          dataType: 'euro',
          calculateCellValue: (row) => centsToEuroString(row.totalSellingPrice),
          sort: {
            dataField: 'totalSellingPrice'
          },
          totals: {
            sum: "totalSellingPrice",
            formatValue: (value) => centsToEuroString(value),
            backend: {
              key: 'sellingPrice'
            }
          },
        },
        {
          caption: 'Prix A. HTVA',
          dataType: 'euro',
          calculateCellValue: (row) => centsToEuroString(row.totalBuyingPrice),
          sort: {
            dataField: 'totalBuyingPrice',
          },
          totals: {
            sum: "totalBuyingPrice",
            formatValue: (value) => centsToEuroString(value),
            backend: {
              key: 'buyingPrice'
            }
          },

        },
        {
          caption: 'Margue',
          dataField: 'margin',
          dataType: 'percentage',
          calculateCellValue: (r) => intToPercentageString(r.margin),
          sort: {
            dataField: 'margin'
          },
          totals: {
            sum: "margin",
            formatValue: (value) => intToPercentageString(value),
            backend: {
              key: 'marge'
            }
          },

        },
        {
          caption: 'Gain',
          dataField: 'gain',
          dataType: 'percentage',
          calculateCellValue: (r) => intToPercentageString(r.gain),
          sort: {
            dataField: 'gain'
          },
          totals: {
            sum: "gain",
            formatValue: (value) => intToPercentageString(value),
            backend: {
              key: 'gain'
            }
          },
        },
        {
          caption: 'GainMontant',
          dataType: 'euro',
          calculateCellValue: (row) => centsToEuroString(row.gainMontant),
          sort: {
            dataField: 'gainMontant'
          },
          totals: {
            sum: "gainMontant",
            formatValue: (value) => centsToEuroString(value),
            backend: {
              key: 'gainMontant'
            }
          },
        },

      ],
      filters: supplierSalesStatsFilters,
      repositoryFunc: OrderRepository.stats.getSupplierSalesStats,
      exportRepositoryFunc: OrderRepository.stats.getSupplierSalesStatsExport,
      asyncExportRepositoryFunc: OrderRepository.stats.getAsyncSupplierSalesStatsExport
    }
  },
  methods: {
      formatFiltersAsQuery() {
          const query = {};

          let filterFromStore =  this.$store.getters['listTable/filters/getFilters'].filter(f => f.tableIdentifier === 'supplierSalesStatsTable')
          filterFromStore.forEach(filter => {
              const key = `filter_${filter.filterKey}`; // Use the required format

              // Handle FILTER_DATETIME_RANGE (or similar structures with start/end values)
              if (filter.type === FILTER_DATETIME_RANGE && filter.filterValue) {
                  if (filter.filterValue.startDate && filter.filterValue.endDate) {
                      query[key] = `${filter.filterValue.startDate},${filter.filterValue.endDate}`;
                  }

              }  else if (Array.isArray(filter.filterValue) && filter.filterKey !== 'supplierIds') {
                  // Join arrays as comma-separated strings
                  query[key] = String(filter.filterValue.join(','));
              } else if (filter.filterValue != null && filter.filterKey !== 'supplierIds') {
                  // Handle other filter types and skip null/undefined values
                  query[key] = `${filter.filterValue}`;
              }

          });

          return query;
      }
  },
}
</script>

<style scoped>

</style>