<template>
  <div>
    <page-title>Imprimer code barre</page-title>

    <card>
      <form-wrapper
        ref="form"
        :show-changed="true"
        submit-button-label="Imprimer"
        @submit="printBarcodeLabel"
      >
        <div>
          <number-input-element
            v-model="barcode"
            label="Code Barre"
            validation-rules="required"
          />
        </div>

        <div class="flex gap-x-3 mt-2">
          <span class="font-medium">Type:</span>
          <radio-button-component
            v-model="type"
            value="EAN13"
            label="EAN13"
          />

          <radio-button-component
            v-model="type"
            value="EAN8"
            label="EAN8"
          />

          <radio-button-component
            v-model="type"
            value="UPCA"
            label="UPCA"
          />

          <radio-button-component
            v-model="type"
            value="2OF5"
            label="2OF5"
          />
        </div>

        <info-alert class="mt-2" size="xs" title="Aucune idée de la signification de ces types ? Alors tu veux probablement le EAN13." />

      </form-wrapper>
    </card>
  </div>
</template>

<script>
import FormWrapper from "@/components/elements/forms/FormWrapper.vue";
import Card from "@/components/elements/cards/Card.vue";
import NumberInputElement from "@/components/elements/forms/elements/base/NumberInputElement.vue";
import PageTitle from "@/components/elements/pages/PageTitle.vue";
import RadioButtonComponent from "@/components/elements/radio/RadioButtonComponent.vue";
import InfoAlert from "@/components/elements/alerts/InfoAlert.vue";
import BarcodeRepository from "@/repositories/BarcodeRepository";

export default {
  name: "PrintBarcodePage",
  components: {InfoAlert, RadioButtonComponent, PageTitle, NumberInputElement, Card, FormWrapper},
  data() {
    return {
      barcode: null,
      type: 'EAN13'
    }
  },
  methods: {
    printBarcodeLabel(callback) {
      if (!this.barcode || !this.type) {
        return false;
      }

      BarcodeRepository.printBarcode(
        this.barcode,
        this.type
      ).then(() => {
        this.barcode = null;
        this.type = "EAN13";
        this.$sendSuccessToast("Étiquette envoyé à l'imprimante!");
      }).finally(() => callback());
    }
  },
}
</script>