<template>
  <div>
    <!-- use same table identifier as in SupplierSalesStats in order to keep same filters -->
    <list-table
      :columns="columns"
      :create-button="false"
      :filters="getFilters"
      :repository-func="repositoryFunc"
      :repository-export-func="exportRepositoryFunc"
      export-button
      :title="title"
      :select-values="selectValues"
      selectable
      enable-totals
      identifier="supplierSalesStatsDetailsTable"
      sub-key="items"
    >
      <template v-slot:product-name-template="{ row }">
        <product-name
          :custom-is-discontinued="row.productIsDiscontinued"
          :custom-is-published="row.productIsPublished"
          :custom-is-visibility-forced="row.productIsShopVisibilityForced"
          :custom-name="row.productName"
        />
      </template>
    </list-table>
  </div>
</template>

<script>
import ListTable from "@/components/listTable/ListTable";
import OrderRepository from "@/repositories/OrderRepository";
import {centsToEuroString, intToPercentageString} from "@/helpers/mathHelper";
import ProductName from "@/components/pages/Products/Subcomponents/ProductName.vue";
import {productOrderStatsFilters} from "@/helpers/listTableModels/ListTableProductOrderStatsFilters";

export default {
  name: "SupplierSalesStatsDetails",
  components: {ProductName, ListTable},
  data() {
    return {
      title: 'Statistiques ventes par fournisseur details ',
        selectValues: product => {
            return {
                productId: product.productId
            };
        },
      columns: [
        {
          caption: '#',
          dataField: 'productId',
          sort: {
            dataField: 'productId'
          }
        },
        {
          caption: 'Name',
          cellTemplate: 'product-name-template',
          sort: {
            dataField: 'productName'
          }
        },
        {
          caption: 'Societe',
          dataField: 'supplierName',
          sort: {
            dataField: 'supplierName'
          }
        },
        {
          caption: 'Nombre',
          dataField: 'totalQuantity',
          sort: {
            dataField: 'totalQuantity'
          },
          totals: {
              sum: "totalQuantity",

          },
        },
        {
          caption: 'PrixVenteTTC',
          dataField: 'totalSellingPriceWithVat',
          calculateCellValue: (row) => centsToEuroString(row.totalSellingPriceWithVat),
          sort: {
            dataField: 'totalSellingPriceWithVat'
          },
          totals: {
            sum: "totalSellingPriceWithVat",
            formatValue: (value) => centsToEuroString(value),
          },
        },
        {
          caption: 'PrixVenteHTVA',
          dataField: 'totalSellingPrice',
          calculateCellValue: (row) => centsToEuroString(row.totalSellingPrice),
          sort: {
            dataField: 'totalSellingPrice'
          },
          totals: {
              sum: "totalSellingPrice",
              formatValue: (value) => centsToEuroString(value),
          },
        },
        {
          caption: 'PrixAchatHTVA',
          dataField: 'totalBuyingPrice',
          calculateCellValue: (row) => centsToEuroString(row.totalBuyingPrice),
          sort: {
            dataField: 'totalBuyingPrice'
          },
        },
        {
          caption: 'Margue',
          dataField: 'margin',
          calculateCellValue: (r) => intToPercentageString(r.margin),
          sort: {
            dataField: 'margin'
          },
        },
        {
          caption: 'Gain',
          dataField: 'gain',
          calculateCellValue: (r) => intToPercentageString(r.gain),
          sort: {
            dataField: 'gain'
          },
        },
        {
          caption: 'GainMontant',
          dataField: 'gainMontant',
          calculateCellValue: (row) => centsToEuroString(row.gainMontant),
          sort: {
            dataField: 'gainMontant'
          },
          totals: {
            sum: "gainMontant",
            formatValue: (value) => centsToEuroString(value),
          },
        },

      ],

      repositoryFunc: (...args) => {
        return OrderRepository.stats.getSupplierSalesStatsDetails(this.$route.params.supplier, ...args);
      },
       exportRepositoryFunc: (...args) => {
        return OrderRepository.stats.getSupplierSalesStatsDetailsExport(this.$route.params.supplier, ...args);
      },
    }
  },
  computed: {
    getFilters() {
        return productOrderStatsFilters.filter(f => f.filterKey !== 'supplierIds');
    }

  }

}
</script>

<style scoped>

</style>